
import Component from "vue-class-component";
import Vue from "vue";
import { ChangePWInterface, Cookie } from "@/plugins/HaiiExAuth";

@Component
export default class HomeView extends Vue {
  chamgePWParam = {} as ChangePWInterface;
  validPw = "";
  at: string | undefined = "";
  aat: string | undefined = "";

  mounted() {
    this.$HaiiExAuth.init({ serviceName: "tess", isProduction: false });
    this.at = Cookie.getCookie("_at");
    this.aat = Cookie.getCookie("_aat");
  }

  signIn() {
    this.$HaiiExAuth.signIn(false);
  }

  adminSignIn() {
    this.$HaiiExAuth.signIn(true);
  }

  async getUserInfo(isAdmin: boolean) {
    console.log(await this.$HaiiExAuth.getUserInfo(isAdmin));
  }

  async signOut(isAdmin: boolean) {
    await this.$HaiiExAuth.signOut(isAdmin);

    this.rewriteToken(isAdmin);
  }

  async refreshToken(isAdmin: boolean) {
    await this.$HaiiExAuth.resetAccessToken(isAdmin);

    this.rewriteToken(isAdmin);
  }

  private rewriteToken(isAdmin: boolean) {
    if (isAdmin) {
      this.aat = Cookie.getCookie("_aat");
      return;
    }
    console.log(Cookie.getCookie("_at"));
    this.at = Cookie.getCookie("_at");
  }

  async copyClipboard(isAdmin: boolean) {
    await this.refreshToken(isAdmin);

    const text = isAdmin ? this.aat : this.at;

    if (!text) {
      alert("토큰이 없습니다.");
      return;
    }
    await navigator.clipboard.writeText(text);
    alert("복붙 완료");
  }
}
