
import Component from "vue-class-component";
import Vue from "vue";

@Component
export default class TestPage extends Vue {
  url =
    "https://forme.haiidev.co.kr/api/asset/message/2022/11/14/26da4a05-9a54-4854-a336-f8b1a2eb7852/fb685234-b818-4540-ac23-81deaf078569/95ce21fb-b52c-4311-8cd4-e30a58646ae5";

  state = "";
  audioCtx = new AudioContext() || (window as any).webkitAudioContext;
  buffer = null as any;

  mounted() {
    this.state = "loading";
    this.load();
  }

  load() {
    const request = new XMLHttpRequest();
    request.open("GET", this.url);
    request.responseType = "arraybuffer";
    request.onload = () => {
      let undecodedAudio = request.response;
      this.audioCtx.decodeAudioData(
        undecodedAudio,
        (data: any) => (this.buffer = data)
      );
    };
    request.send();
  }

  play() {
    const source = this.audioCtx.createBufferSource();
    source.buffer = this.buffer;
    source.connect(this.audioCtx.destination);
    source.start();
    this.state = "play";
  }
}
